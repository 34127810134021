import styled from "styled-components";

export const Container = styled.div``;

export const SectionA = styled.div`
  padding: 30px;
  > .title {
    font-weight: 700;
    font-size: 2rem;
  }
`;
export const SectionB = styled.div``;
